<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form  :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="生产厂家:" prop="factory">
            <el-input v-model="searchModel.factory"></el-input>
          </el-form-item>
          <el-form-item label="材料名称:" prop="materialName">
            <el-input v-model="searchModel.materialName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button  type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">
              {{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">
              停止
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              v-auth="'purchaseManage_purchasePlan_add'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;purcEditModel = undefined"
            >新增采购计划
            </el-button>
          </el-form-item>
        </el-form>

      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="purchasePlan"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :data="tableData">
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column field="factory" title="生产厂家" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="creatDate" title="订单日期" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="supplier" title="供应商" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="materialName" title="材料名称" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="model" title="品种规格" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="planNum" title="预计数量" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="receivEnterprise" title="收货企业" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="receivSite" title="收货地点" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>

          <vxe-table-column field="sendDate" title="送货日期开始(精确到日)" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>

          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button v-auth="'purchaseManage_purchasePlan_detail'" type="success" icon="el-icon-tickets" circle size="mini" @click="editAble=false;detailRow(row)" title="详情"/>
              <el-button v-auth="'purchaseManage_purchasePlan_edit'" type="primary" icon="el-icon-edit" circle size="mini" @click="editAble=true;detailRow(row)" title="修改"/>
              <el-button v-auth="'purchaseManage_purchasePlan_delete'"  type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>

      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <!-- 编辑采购计划 -->
      <purchasePlanDetail :visible.sync="dialogEdit" :edit-data.sync="purcEditModel" @refresh="refreshData"  :region-options="regionOptions" :region-props="regionProps" :edit-able ="editAble"/>
    </div>
  </page-container>
</template>

<script>
import purchasePlanDetail from './purchasePlanDetail'
import { getPurchasePlanPage, getPurchasePlanOne, delPurchasePlan } from '@/apis/purchaseManage/purchaseManage'

export default {
  name: 'purchasePlan',
  data () {
    return {
      editAble: true,
      searchModel: {
        factory: '',
        materialName: ''
      },
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      searchText: '查询',
      allAlign: 'center',
      tableData: [],
      regionArr: [],
      regionOptions: [],
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                // gainRegi({
                //   isMapJson: false,
                //   regiLevel: node.level,
                //   regiCodes: [node.data.code]
                // }).then((res) => {
                //   if (res.code == 200) {
                //     const nodeArr = res.data[0].regiCountrs.map((val) => ({
                //       value: val.regiCountrId,
                //       label: val.countrName,
                //       code: val.countrCode,
                //       leaf: true
                //     }))
                //     resolve(nodeArr)
                //   }
                // })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      editMode: 'add',
      projDetailModel: {},
      // 所属区域
      selectedOptions: [],
      loading: false,
      dialogEditTitle: '新增采购计划',
      dialogEdit: false,
      purcEditModel: {}
    }
  },
  methods: {
    handleItemChange (val) {
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      this.tableData = []
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    refreshData () {
      this.loading = true
      getPurchasePlanPage({
        factory: this.searchModel.factory,
        materialName: this.searchModel.materialName,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.currentPage
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows
          this.tablePage.total = res.data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        this.logining = false
      })
    },
    detailRow (row) {
      getPurchasePlanOne({ id: row.purchasingPlanId }).then(res => {
        const details = res.data
        const rowData = {}

        XEUtils.objectEach(details, (item, key) => {
          rowData[key] = details[key] ? details[key].toString() : ''
        })
        this.purcEditModel = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该采购记录?', '删除采购记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delPurchasePlan({
          ids: [row.purchasingPlanId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  mounted () {
    this.refreshData()
  },
  components: {
    purchasePlanDetail
  }

}
</script>

<style scoped>

</style>
