import { render, staticRenderFns } from "./purchasePlanDetail.vue?vue&type=template&id=683a0606&scoped=true&"
import script from "./purchasePlanDetail.vue?vue&type=script&lang=js&"
export * from "./purchasePlanDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683a0606",
  null
  
)

export default component.exports