import http from '@/utils/http'
// 采购计划管理分页查询
export const getPurchasePlanPage = p => http.post('/purchas/purchasPlan/page', p)

// 采购计划详情
export const getPurchasePlanOne = p => http.post('/purchas/purchasPlan/one', p)

// 添加修改采购计划
export const purchasePlanAddMod = p => http.post('/purchas/purchasPlan/addMod', p)

// 逻辑删除采购计划
export const delPurchasePlan = p => http.post('/purchas/purchasPlan/del', p)

// 获取采购计划列表  支持新增时  可选择采购计划  填写供应商
export const getPurchasPlanList = p => http.post('/purchas/purchasPlan/purchasPanList', p)

// 采购订单管理列表分页查询
export const getpurchasOrderpage = p => http.post('/purchas/purchasOrder/page', p)

// 采购订单调整(新增或修改)
export const purchasOrderAddMod = p => http.post('/purchas/purchasOrder/addMod', p)

// 采购订单删除
export const delpurchasOrder = p => http.post('/purchas/purchasOrder/del', p)

// 单条采购订单
export const getpurchasOrderone = p => http.post('/purchas/purchasOrder/one', p)

// 获取采购订单列表
export const getPurchasOrderList = p => http.post('/purchas/purchasOrder/more', p)

// 获取单位列表
export const getFirmList = p => http.post('/purchas/purchasOrder/getFirm', p)

// 预约单管理列表分页查询
export const getreservationManagepage = p => http.post('/purchas/reservationManage/page', p)
// 预约单管理新增供应商列表下拉
export const getgdtSupplier2 = p => http.post('/purchas/gdtSupplier2/page', p)
// 预约单管理新增驾驶员列表下拉
export const getdriverList = p => http.post('/purchas/reservationManage/driverlist', p)
// 预约单管理生成运单编号
export const creatWaybillNumber = p => http.post('/purchas/reservationManage/creatWaybillNumber', p)

// 车牌信息列表
export const getbusLicPlatelist = p => http.post('/purchas/reservationManage/busLicPlatelist', p)

// 预约单管理调整(新增或修改)
export const reservationManageAddMod = p => http.post('/purchas/reservationManage/addMod', p)

// 预约单管理删除
export const delreservationManage = p => http.post('/purchas/reservationManage/del', p)

// 单条预约单管理
export const getreservationManageone = p => http.post('/purchas/reservationManage/one', p)

// 地磅流水列表
export const getWeighbridgePage = p => http.post('/purchas/weighbridge/page', p)
