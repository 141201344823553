<template>
  <el-dialog v-bind="{title:dialogTitle,width:'860px'}"
             @open="handlerOpen"
             @close="handlerClose"
             :visible.sync="visibleDialog">
    <el-form :inline="true" ref="form" size="mini" label-position="right"
             label-width="200px"
             :rules="rules"
             :model="submitForm"
             style="margin-top: 20px"
    >
      <el-row>
        <el-form-item label="生产厂家：">
          <el-input v-model="submitForm.factory" style="width: 180px">
          </el-input>

        </el-form-item>
        <el-form-item label="送货日期:">
          <el-date-picker
            v-model="submitForm.sendDate"
            type="datetime"
            placeholder="选择日期时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="材料名称：" prop="materialName">
          <el-input v-model="submitForm.materialName" style="width: 180px">
          </el-input>

        </el-form-item>
        <el-form-item label="品种规格：">
          <el-input v-model="submitForm.model" style="width: 180px">
          </el-input>

        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="采购计划数量(吨):" prop="planNum">
          <el-input v-model="submitForm.planNum" style="width: 180px">
          </el-input>
        </el-form-item>
        <el-form-item label="供应商:" prop="supplier">
          <el-input v-model="submitForm.supplier" style="width: 180px">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="收货企业:" prop="receivEnterprise" >
          <el-input v-model="submitForm.receivEnterprise" style="width: 180px">
          </el-input>

        </el-form-item>
        <el-form-item label="收货站点:">
          <el-input v-model="submitForm.receivSite">
          </el-input>

        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="订单日期:">
          <el-date-picker
            v-model="submitForm.creatDate"
            type="datetime"
            placeholder="选择日期时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog__footer" v-show="editAble">
      <el-button @click="visibleDialog=false" size="mini">取消</el-button>
      <el-button type="primary" @click="submit" size="mini">保存</el-button>
    </div>
  </el-dialog>

</template>

<script>
import { purchasePlanAddMod, delpurchasOrder, getgdtSupplier2 } from '@/apis/purchaseManage/purchaseManage'

export default {
  name: 'purchasePlanDetail',
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    relaItems: {
      type: Array,
      default () {
        return []
      }
    },
    regionOptions: {
      type: Array,
      default () {
        return []
      }
    },
    regionProps: {
      type: Object
    },
    regionArr: {
      type: Array,
      default () {
        return []
      }
    },
    editAble: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      supplierList: [],
      compList: [],
      loading: false,
      submitForm: {
        factory: '',
        model: '',
        receivEnterprise: '',
        receivSite: '',
        supplier: '',
        supplierId: '',
        planNum: '',
        materialName: '',
        sendDate: '',
        creatDate: '',
        firm: ''
      },
      dialogTitle: '',
      rules: {
      }
    }
  },
  methods: {
    handleItemChange (val) {
    },
    removeMonRow (index, id, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          delpurchasOrder({
            ids: [id]
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              rows.splice(index, 1)
            }
          })
        } else {
          rows.splice(index, 1)
        }
      })
    },
    async firmChange (val) {
      this.submitForm.firm = ''
      this.submitForm.relaId = ''
      console.log(JSON.stringify(val) + '供货厂商relaId的数据')
      for (let i = 0; i < this.firmList.length; i++) {
        if (val == this.firmList[i].relaId) {
          console.log(JSON.stringify(this.firmList[i].firm) + '供货厂商的数据')
          this.submitForm.firm = this.firmList[i].firm
        }
      }
    },
    submit () {
      const param = this.submitForm
      purchasePlanAddMod(param).then(res => {
        if (res.code === 200) {
          if (this.submitForm.finaPurcId != '') {
            this.$message.success('修改成功')
          } else {
            this.$message.success('添加成功')
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlerOpen () {
      this.userName = this.$store.state.account.loginInfo.loginName
      this.submitForm.supplier = this.userName
      if (this.editAble) {
        this.dialogTitle = this.editData ? '编辑采购计划' : '新建采购计划'
      } else {
        this.dialogTitle = '采购计划详情'
      }
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },
    handlerClose () {
      this.$emit('update:editData', null)
      this.submitForm = {
        carrier: '',
        factory: '',
        leaveFactoryTime: '',
        model: '',
        receivEnterprise: '',
        receivSite: '',
        supplier: '',
        planNum: '',
        materialName: '',
        sendDate: '',
        creatDate: ''

      }
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    // 关联供应商
    getgdtSupplier2().then(res => {
      console.log(666, res)
      this.supplierList = res.data.rows
    })
  }
}
</script>

<style scoped>

</style>
